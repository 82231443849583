<template>
  <div class="text-center pa-7">
    <v-card max-width="100" flat class="mx-auto text-center" color="transparent">
      <span class="caption">{{ text || 'Please wait...' }}</span>
      <v-progress-linear
        color="accent"
        indeterminate
        rounded
        height="3"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Preloader',

  props: {
    text: String
  }
}

</script>
